import {
  setCompany,
  setEmail,
  setLogo,
  setName,
  setPhone,
  setTitle,
} from "../redux/info";

import Creatable from "react-select/creatable";
import Select from "react-select";
import { useDispatch } from "react-redux";

export default function Inputs() {
  const dispatch = useDispatch();

  const onChangeName = (e) => {
    dispatch(setName(e.target.value));
  };
  const onChangeTitle = (e) => {
    dispatch(setTitle(e.target.value));
  };
  const onChangePhone = (e) => {
    dispatch(setPhone(e.target.value));
  };
  const onChangeEmail = (e) => {
    dispatch(setEmail(e.target.value));
  };
  const onChangeCompany = (e) => {
    dispatch(setCompany(e?.value));
  };
  const onChangeLogo = (e) => {
    dispatch(setLogo(e?.value));
  };

  const companyOptions = [
    { value: "Akademiet", label: "Akademiet" },
    {
      value: "Akademiet Videregående Skole",
      label: "Akademiet Videregående Skole",
    },
    { value: "Akademiet Realfagsskole", label: "Akademiet Realfagsskole" },
    { value: "Akademiet Ungdomsskole", label: "Akademiet Ungdomsskole" },
    { value: "Akademiet Privatistskole", label: "Akademiet Privatistskole" },
    {
      value: "Akademiet Privatistskole og Nettstudier",
      label: "Akademiet Privatistskole og Nettstudier",
    },
    { value: "Akademiet Nettstudier", label: "Akademiet Nettstudier" },
    { value: "Akademiet Utveksling", label: "Akademiet Utveksling" },
    {
      value: "Akademiet Videreutdanning",
      label: "Akademiet Videreutdanning",
    },
    { value: "Akademiet VGS Bergen", label: "Akademiet VGS Bergen" },
    { value: "Akademiet VGS Drammen", label: "Akademiet VGS Drammen" },
    { value: "Akademiet VGS Fredrikstad", label: "Akademiet VGS Fredrikstad" },
    {
      value: "Akademiet VGS Bislett",
      label: "Akademiet VGS Bislett",
    },
    {
      value: "Akademiet VGS Bragernes Drammen",
      label: "Akademiet VGS Bragernes Drammen",
    },
    {
      value: "Akademiet VGS Kristiansand",
      label: "Akademiet VGS Kristiansand",
    },
    { value: "Akademiet VGS Oslo", label: "Akademiet VGS Oslo" },
    { value: "Akademiet VGS Sandnes", label: "Akademiet VGS Sandnes" },
    { value: "Akademiet VGS Ypsilon", label: "Akademiet VGS Ypsilon" },
    { value: "Akademiet VGS Ålesund", label: "Akademiet VGS Ålesund" },
    {
      value: "Akademiet Realfagsgymnas Sandvika",
      label: "Akademiet Realfagsgymnas Sandvika",
    },
    {
      value: "Akademiet Realfagsskole Drammen",
      label: "Akademiet Realfagsskole Drammen",
    },
    {
      value: "Akademiet Realfagsskole Oslo",
      label: "Akademiet Realfagsskole Oslo",
    },
    {
      value: "Akademiet Realfagsskole Ålesund",
      label: "Akademiet Realfagsskole Ålesund",
    },
    {
      value: "Akademiet Ungdomsskole Lier",
      label: "Akademiet Ungdomsskole Lier",
    },

    { value: "Akademiet Norge", label: "Akademiet Norge" },
    {
      value: "Akademiet Norsk Restaurantskole",
      label: "Akademiet Norsk Restaurantskole",
    },
  ];
  const logoOptions = [
    { value: null, label: "Uten logo" },
    { value: "akademiet", label: "Akademiet" },
    { value: "akademiet-vgs", label: "Akademiet Videregående Skole" },
    { value: "akademiet-realfag", label: "Akademiet Realfagsskole" },
    { value: "akademiet-realfagsgymnas", label: "Akademiet Realfagsgymnas" },
    { value: "akademiet-ungdomsskole", label: "Akademiet Ungdomsskole" },
    { value: "akademiet-privatist", label: "Akademiet Privatistskole" },
    {
      value: "akademiet-privatist-nett",
      label: "Akademiet Privatistskole og Nettstudier",
    },
    { value: "akademiet-nett", label: "Akademiet Nettstudier" },
    { value: "akademiet-utveksling", label: "Akademiet Utveksling" },
    {
      value: "akademiet-videreutdanning",
      label: "Akademiet Videreutdanning",
    },
    {
      value: "akademiet-norsk-restaurantskole",
      label: "Akademiet Norsk Restaurantskole",
    },
  ];

  const customDropdownStyles = {
    option: (styles, state) => {
      return {
        ...styles,
        backgroundColor: state.isFocused ? "#FEF2E6" : null,
        "&:active": {
          backgroundColor: "#FEF2E6",
        },
        color: "#000000",
      };
    },
    control: (styles, state) => ({
      ...styles,
      border: "1px solid #ccc",
      boxShadow: state.isFocused && "0 0 0 1px #f08920",
      /* eslint-disable-next-line */
      border: state.isFocused && "1px solid #f08920",
      "&:hover": {
        border: state.isFocused && "1px solid #f08920",
      },
    }),
  };

  return (
    <div className="inputs">
      <h2>Detaljer</h2>
      <label>
        <p className="input-label">Navn</p>
        <input type="text" onChange={onChangeName} />
      </label>
      <label>
        <p className="input-label">Tittel</p>
        <input type="text" onChange={onChangeTitle} />
      </label>
      <label>
        <p className="input-label">Skole/selskap</p>
        <Creatable
          className="dropdown"
          classNamePrefix="dropdown"
          isClearable={true}
          options={companyOptions}
          formatCreateLabel={(value) => <span>{value}</span>}
          onChange={onChangeCompany}
          styles={customDropdownStyles}
          placeholder=""
        />
      </label>
      <label>
        <p className="input-label">Telefon</p>
        <input type="text" onChange={onChangePhone} />
      </label>
      <label>
        <p className="input-label">E-post</p>
        <input type="email" onChange={onChangeEmail} />
      </label>
      <label>
        <p className="input-label">Logo</p>
        <Select
          className="dropdown"
          classNamePrefix="dropdown"
          isClearable={true}
          options={logoOptions}
          onChange={onChangeLogo}
          placeholder=""
          noOptionsMessage={() => "Ingen resultat"}
          styles={customDropdownStyles}
        />
      </label>
    </div>
  );
}
